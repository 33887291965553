import React from 'react';
import { HeroLayout2 } from '../../ui-components';
import { APP_NAME, ROUTES } from '../../utils/utils';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Banner = () => {
	const { t } = useTranslation('app');
	const navigate = useNavigate();

	return (
		<ErrorBoundary fallback={<div>Something went wrong</div>}>
			<HeroLayout2
				overrides={{
					HeroLayout3: {
						companyName: APP_NAME,
						companyDetails: t('messages.companyDetails'),
					},
					image: { 
						src: require('../../images/plantation.jpg'), 
						style: {
							width: '100%',
							maxWidth: '1440px',
							height: 'auto',
							objectFit: 'cover'
						  }
					},
				}}
				onClick={() => navigate(ROUTES.founders)}
			/>
		</ErrorBoundary>
	);
};

export default Banner;
